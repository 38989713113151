<template>
  <section>
    <el-row>
        <el-form :inline="true" size="mini" style="padding-left:10px;margin-bottom:-25px">
            <!--<el-form-item label="部门名称">
      <el-input v-model="usernameinput" placeholder="部门名称" size="mini"></el-input>
    </el-form-item>-->
            <el-form-item label="部门名称">
                <el-input placeholder="输入部门名称"
                          v-model="filterText">
                </el-input>
            </el-form-item>
            
            <!--<el-form-item>
      <el-button type="primary" size="mini" plain @click="postsearch">查询</el-button>
    </el-form-item>-->
        </el-form> 
 </el-row>
    <!--组织维护树形工具条-->
    <div id="userdiv" style="margin-top: 10px;">
      <el-col :span="10" style="padding-left:10px;padding-right:10px;">
        <el-divider>组织结构</el-divider>
        <div id="dpttree">
            <!--<el-tree :load="loadDptNode" :props="RoleProps" lazy
                     @node-click="PostClick" v-if="datatree" highlight-current
                     node-key="Id" :data="departmentsearchdata"
                     :render-content="renderContent" ref="tree1"></el-tree>-->

            <el-tree 
                     :data="departmentsearchdata"
                     :props="defaultProps"
                     :filter-node-method="filterNode"
                     @node-click="nodeClickPost"
                     :render-content="renderContent"
                     ref="tree">
            </el-tree>
        </div>
      </el-col>
      <!--待选人员列表-->
      <el-col :span="10" style="padding-left:10px;padding-right:10px;">
        <!-- <el-card> -->
      
       <div class="orgbtndivtree">
        <el-divider>职务</el-divider>
        <div class="orgtree">
            <!--<el-tree :data="postdata"
                     :load="loadPostNode"
                     :props="PostProps"
                     :expand-on-click-node="false"
                     lazy
                     @node-click="PositionClick"
                     highlight-current
                     node-key="treetwokey"
                     ref="tree2">
                <span class="custom-tree-node" slot-scope=" {node,data} ">
                    <span>
                        <img v-if="data.Type == '职务'" alt />
                        <img v-else alt />
                    </span>
                </span>
            </el-tree>-->

            <el-tree 
                     :data="postdata"
                     :props="defaultPostProps"
                     @node-click="PositionClick"
                     ref="tree2">
                
            </el-tree>
        </div>
      </div>
      </el-col>
    </div>
    <div slot="footer" style="text-align: center;">
      <el-button type="primary" size="mini" plain @click="addNewSubmit">提交</el-button>
    </div>
    <!-- </el-dialog> -->
  </section>
</template>
<script>
import { GetChildrenList, GetDepartmentTypes, GetManagePost,GetPostChildrenList,GetDepartmentList} from '../api/hr';
export default {
  name: "UserChoosejob",
  data () {
      return {
          filterText: '',
      //   dialogWidth: 0,
      postdata: [],
      node2: null,    //暂存选中node信息
      alluserradioshow: false,
      usernameinput: null, 
      deptname: null,   //双向绑定部门name
      datatree: false,   //树重新加载
      TypeIdModules: [],      //部门类型集合
       departmentsearchdata: [],
      RoleProps: {
        label: 'Name',
        isLeaf: 'Leaf'
      },
      PostProps: {
        label: 'Name',
        isLeaf: 'Leaf'
      },
          position: [], // 选择的职务
          selPost: {},//  选中职务
      defaultProps: {
              children: 'children',
              label: 'label'
          },
          defaultPostProps: {
              label: 'label',
              children: 'children'
          },
    }
  },
  props: ["data", "all", "single"], //接受父组件传值
  methods: {
      filterNode(value, data) {
          if (!value) return true;
          return data.label.indexOf(value) !== -1;
      },

     postsearch () {
      let para = { name: this.usernameinput };
          GetDepartmentList(para).then((res) => {
              console.log("GetDepartmentList:",res);
          //var myArray = new Array();
        //for (var i = 0; i < res.data.response.length; i++) {
        //  myArray.push(res.data.response[i]);
        //}
              this.departmentsearchdata = res.data.data.dept;
        //this.$refs.tree1.setCurrentKey(null);
      });
    },
    //组织维护节点加载初始化
    loadDptNode (node, resolve) {
      if (node.level === 0) {
        this.loadfirstnode(resolve);
      }
      if (node.level >= 1 && node.isLeaf == false) {
        this.loadchildnode(node, resolve);
      }
    },
    //组织维护加载第一级节点
    loadfirstnode (resolve) {
      GetChildrenList().then((res) => {
        const roledata = res.data.response;
        resolve(roledata);
      });
    },
    //组织维护加载节点的子节点集合
    loadchildnode (node, resolve) {
      let para = { departmentId: node.data.Id };
      GetChildrenList(para).then((res) => {
        const roledata = res.data.response;
        resolve(roledata);
      });
    },
    renderContent (h, { node, data, store }) {
      var icon = "";
      for (var i = 0; i < this.TypeIdModules.length; i++) {
        var tm = this.TypeIdModules[i];
        if (tm.Id == data.TypeId) {
          icon = tm.Icon;
          break;
        }
      }
      return (
        <span class="custom-tree-node">
          <i class={icon}>{node.label}</i>
        </span>
      )
    },
     //职务维护节点加载初始化
    loadPostNode (node, resolve) {
      if (node.level === 0) {
        // console.log("无节点");
      }
      if (node.level >= 1 && node.isLeaf == false) {
        this.loadPostchildnode(node, resolve);
      }
    },
    //职务维护加载节点的子节点集合
    loadPostchildnode (node, resolve) {
      let para = { postId: node.data.Id };
      GetPostChildrenList(para).then((res) => {
        const roledata = res.data.response;
        resolve(roledata);
      });
    },
    //职务树节点点击事件
    PostClick (node) {
      console.log(node)
      this.deptname = node.Name;
      this.node2 = node;
      let para = { departmentId: node.Id };
       GetManagePost(para).then((res) => {
        if (res.data.response != null) {
          var myArray = new Array();
          myArray[0] = res.data.response;
          this.postdata = myArray;
        } else {
          this.postdata = null;
        }
      });
     
      },
      // Tree node 点击消息 
      nodeClickPost(node) {
          //console.log("nodeClickPost", node);
          let para = { deptId: node.id };
          GetManagePost(para).then((res) => {
              if (res.data.data != null) {
                 // console.log("res.data.data", res.data.data);
                  this.postdata = res.data.data.job;
                  console.log("this.postdata", this.postdata);
              } else {
                  this.postdata = null;
              }
          });
      },
    PositionClick(node){
      console.log(node)
        this.position = node;
        this.selPost = {
            Name : node.label,
            DepartmentId: node.deptId,
            Id: node.id,
            WorkTypeId: node.workTypeId,
            workTypeName:node.workTypeName
        }
   },
    //提交选中新人员弹框
    addNewSubmit () {
  
        //console.log(this.position);

        //this.$emit("callback", this.position); //将值传给父组件
        this.$emit("callback", this.selPost); //将值传给父组件
    },
   
  },
  mounted () {
    //GetDepartmentTypes().then((res) => {
      
    //  this.TypeIdModules = res.data.response.data;
    //});
    let check = this.data instanceof Array;
    let para;
    if (this.all) {
      this.alluserradioshow = true;
    } else {
      this.alluserradioshow = false;
    }
    this.userform = [];
    this.datatree = true;
    if (check == true && this.data.length != 0) {
      para = { ids: this.data };
    } else {
      para = { ids: [] };
    }
    // GetUserChooseList(para).then((res) => {
    //   this.gotuserform = res.data.response;
      // });
      this.postsearch();
  },
  watch: {   //监听值变化
    data: function () {

      },
      filterText(val) {
          this.$refs.tree.filter(val);
      }
  }
}
</script>
<style scoped>
.el-col
  >>> .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #fabe64;
}

.el-col >>> .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
}
.el-col >>> .el-icon-caret-right:before {
  content: url("../assets/add.png") !important;
  font-size: 16px;
}
.el-col >>> .expanded:before {
  content: url("../assets/cancel.png") !important;
  font-size: 16px;
}
.el-col >>> .is-leaf.el-tree-node__expand-icon.el-icon-caret-right:before {
  content: "" !important;
  font-size: 16px;
}

.el-tree {
  min-width: 100%;
  font-size: 14px;
  display: inline-block;
}

#userdiv {
  height: calc(100vh - 350px);
}
.el-tree {
  min-width: 100%;
  font-size: 14px;
  display: inline-block;
}
#dpttree {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 440px);
  /*width:200px;*/
  border: 0px solid blue;
}
#usertable {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 440px);
  /*width:200px;*/
  border: 0px solid blue;
}
#chooseusertable {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 440px);
  /*width:200px;*/
  border: 0px solid blue;
}
/* .el-scrollbar .el-scrollbar__wrap {overflow-x: hidden;}
.el-tree>.el-tree-node{

height: 350px; 
min-width: 100%;
display:inline-block;
} */
</style>