<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm"
          :model="selectForm"
          class="select_form"
        >
          <el-form-item label="姓名:">
            <el-input v-model="selectForm.name" placeholder="姓名"></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <!--列表-->
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
     
        @current-change="selectCurrentRow"
        @row-dblclick="handleShow"
        class="new_table"
      >
        <el-table-column prop="UserName" label="姓名"> </el-table-column>
        <el-table-column label="公司" prop="Factory"></el-table-column>
        <el-table-column label="部门" prop="Dpt"></el-table-column>
        <el-table-column label="岗位" prop="Post"></el-table-column>
        <el-table-column label="分类" prop="Type"></el-table-column>
        <el-table-column label="是否在公司" prop="IsFactory">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.IsFactory ? 'success' : 'danger'"
              disable-transitions
            >
              {{ scope.row.IsFactory ? "是" : "否" }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="荣誉级别" prop="Level"></el-table-column>

        <el-table-column
          label="获取时间"
          prop="GetDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column
          label="颁发单位"
          prop="IssuedUnits"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="具体描述"
          show-overflow-tooltip
          prop="Explain"
        ></el-table-column>
        <el-table-column label="审核状态" prop="State">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.State == '已确认'"
              type="success"
              disable-transitions
            >
              {{ scope.row.State }}
            </el-tag>
            <el-tag
              v-else-if="scope.row.State == '已退回'"
              type="danger"
              disable-transitions
            >
              {{ scope.row.State }}
            </el-tag>
            <el-tag
              v-else-if="scope.row.State == '未确认'"
              type="primary"
              disable-transitions
            >
              {{ scope.row.State }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper ,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog
      :title="operation ? '查看' : '审核'"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="SakaryForm"
        :model="SakaryForm"
        class="demo-form-inline"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名:" prop="UserName">
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.UserName"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公司:" prop="Factory">
              <el-input
                type="text"
                v-model="SakaryForm.Factory"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="部门:" prop="Dpt">
              <el-input type="text" v-model="SakaryForm.Dpt" disabled>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="岗位:" prop="Post">
              <el-input type="text" :disabled="true" v-model="SakaryForm.Post">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="分类:" prop="Type">
              <el-select
                v-model="SakaryForm.Type"
                placeholder="请选择"
                style="width: 100%"
                :disabled="true"
              >
                <el-option label="荣誉称号" value="荣誉称号"></el-option>
                <el-option label="荣誉奖励" value="荣誉奖励"></el-option>
                <el-option label="专利" value="专利"></el-option>
                <el-option label="论文" value="论文"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="荣誉级别:" prop="Level">
              <el-select
                v-model="SakaryForm.Level"
                placeholder="请选择"
                style="width: 100%"
                :disabled="true"
              >
                <el-option label="国家级" value="国家级"></el-option>
                <el-option label="省级" value="省级"></el-option>
                <el-option label="市级" value="市级"></el-option>
                <el-option label="区级" value="区级"></el-option>
                <el-option label="公司级" value="公司级"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="获取时间:" prop="GetDate">
              <el-date-picker
                type="date"
                v-model="SakaryForm.GetDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                :disabled="true"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="颁发单位:" prop="IssuedUnits">
              <el-input
                type="text"
                v-model="SakaryForm.IssuedUnits"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否在公司:" prop="IsFactory">
              <el-switch v-model="SakaryForm.IsFactory" :disabled="true">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="具体描述">
              <el-input
                type="textarea"
                v-model="SakaryForm.Explain"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="审核:"
              prop="State"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="SakaryForm.State"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option label="已确认" value="已确认"></el-option>
                <el-option label="已退回" value="已退回"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="备注"
              prop="remark"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input type="textarea" v-model="SakaryForm.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <upload-files
          :files="SakaryForm.FileArry"
          :key="key"
          action="/cyl/ftp/ftp/upload"
          :limit="20"
          @fun="dealFiles"
          :IsDisabled="operation1"
          :IsDel="operation1"
        ></upload-files>
        <el-row v-if="operation1">
          <el-col :span="24" style="text-align: center">
          
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')"
                >提交</el-button
              >
           
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog
     title="选择人员"
      :visible.sync="addUserVisible"
      v-model="addUserVisible"
      width="75%"
      center
      append-to-body
    >
      <UserChoose
        :data="choosedusers"
        :all="true"
        :single="false"
        @callback="usercallFunction"
      ></UserChoose>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UserChoose from "../../components/UserChoose";
import UserChoosejob from "../../components/UserChoosejob";
import UploadFiles from "../../components/UploadFiles";
import {
  GetHonorSubmitInfoPageList,
  UpdateHonorInfoState,
  GetUserPostDptOrg,
  GetHonorDetail,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar, UserChoose, UserChoosejob, UploadFiles },
  data() {
    return {
      loading: true,
      searchVal: "",
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      key: 0,
      SakaryVisible: false,
      buttonListmsg: "",
      users: [],
      choosedusers: [],
      Intochoosedusers: [],
      operation: false, // true:新增, false:编辑
      operation1: true,
      addUserVisible: false,
      IntoUserVisible: false,
      buttonList: [],
      SakaryForm: {
        UserId: "",
        UserName: "",
        Post: "",
        Dpt: "",
        Type: "",
        IsFactory: true,
        Level: "",
        IssuedUnits: "",
        GetDate: "",
        Explain: "",
        Factory: "",
        FileArry: [],
      },
      selectForm: {
        name: "",
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    usercallFunction(newdata) {
      if (newdata.length > 1) {
        this.$message({
          message: "不允许选择多人",
          type: "error",
        });
        return;
      }
      this.SakaryForm.UserId = newdata[0].Id;
      this.SakaryForm.UserName = newdata[0].UserNameClaim;
      GetUserPostDptOrg({ userId: newdata[0].Id }).then((res) => {
        if (res.data.success) {
          this.SakaryForm.Factory = res.data.response.OrganizationName;
          this.SakaryForm.Dpt = res.data.response.DepartmentName;
          this.SakaryForm.Post = res.data.response.PostName;
        } else {
          this.$message({
            message: "获取部门信息出错",
            type: "error",
            duration: 5000,
          });
        }
      });
      this.addUserVisible = false;
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    chooseUser() {
      this.addUserVisible = true;
    },

    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        userName: this.selectForm.name,
      };

      GetHonorSubmitInfoPageList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      GetHonorDetail({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.key += 1;
        this.SakaryVisible = true;
      });

      this.operation = false;
      this.operation1 = true;
    },
    handleShow() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      GetHonorDetail({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.key += 1;
        this.SakaryVisible = true;
      });

      this.operation = false;
      this.operation1 = false;
    },
    handleAdd() {
      (this.SakaryForm = {
        UserId: "",
        UserName: "",
        Post: "",
        Dpt: "",
        Type: "",
        IsFactory: true,
        Level: "",
        IssuedUnits: "",
        GetDate: "",
        Explain: "",
        Factory: "",
        FileArry: [],
      }),
        (this.key += 1);
      (this.operation = true), (this.SakaryVisible = true);
      this.operation1 = true;
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.SakaryForm);
            UpdateHonorInfoState(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 5000,
                });
                this.SakaryVisible = false;
                this.getData(false);
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                  duration: 5000,
                });
              }
            });
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
    dealFiles(data) {
      console.log(data.backData);
      this.SakaryForm.FileArry = data.backData;
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

