
<template>
  <div class="uploadFils">
    <el-upload
      class="upload-demo"
      :action="currseAction"
               :headers ="token"
      ref="uploaddemo"
      :limit="limit" 
      :multiple="true"
      :on-success="AttachOnSuccess"
      :before-upload="beforeAvatarUpload"
      :show-file-list="showlist"
    >
      <!-- :multiple="multiple" -->
        <el-button size="small"
                   v-if="IsDisabled"
                   type="primary"
                   style="margin: 5px"
                   @click="clickButton()">{{btnName}}</el-button>
    </el-upload>
    <el-table
      :data="newList"
      border 
      stripe
      style="width: 100%"
      v-if="newList.length > 0"
    >
      <el-table-column prop="FileName" :label="tabName">
        <template slot-scope="scope">
          <el-button size="small" type="text">
            <a @click="linkDownload(scope.row.URL)">{{ scope.row.FileName }}</a>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column width="80px">
        <template slot-scope="scope">
          <el-button size="small" type="text" v-if="IsDownLoad">
            <a target="_blank" :href="scope.row.URL">下载</a>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column width="80px">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="text"
            v-if="IsDel"
            @click="deleteButton(scope.$index)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
    export default {
        props: {
            //文件列表
            files: {
                type: Array,
                default: () => [],
            },
            //是否展示删除列(默认显示删除列,可自定义)
            isShowDel: {
                type: Boolean,
                default: true,
            },

            multiple: {
                type: Boolean,
                default: true,
            },
            IsDisabled: {
                type: Boolean,
                default: true,
            },
            IsDel: {
                type: Boolean,
                default: true,
            },
            IsDownLoad: {
                type: Boolean,
                default: true,
            },
            action: String,
            limit: Number,
            menuKey: String,
            token: {
                type: Object,
                default: function () {
                    return { "Authorization": "Bearer " + window.localStorage.getItem("access_token") }
                },
            },
            typeName: {
                type: String,
                default: "",
            },
  },
  data() {
      return {
          showlist: true,
          currseAction:"https://api.gradgroup.cn/ftp/ftp/upload",
          newList: [],
          btnCurName: this.typeName,
    };
  },

  methods: {
      clickButton() {
          //this.token = window.localStorage.getItem("access_token");
          //console.log("el-upload:", this.headers)
          //console.log("el-upload:", window.localStorage.getItem("access_token"))
      this.$refs.uploaddemo.clearFiles();
      this.showlist = true;
    },
    //上传前判断
    beforeAvatarUpload(file) {
      //文件数量判断
      if (this.newList != null) {
        const isLt5 = this.newList.length < this.limit;
        if (!isLt5) {
          this.$message.error("上传文件数量不能超过" + this.limit + " 个!");
        }
        return isLt5;
      }
      this.showlist = true;
    },
    //根据索引删除文件列表中的文件
    deleteButton(index) {
      this.newList.splice(index, 1);
      this.$emit("delfun", { backData: this.newList });
    },
    AttachOnSuccess(response, file, fileList) {
      console.log(response);
      this.showlist = false;
      //let fileArray = {
      //  FileName: response.response.FileName,
      //  URL: response.response.HttpHost + response.response.RemotePath,
      //};
        let fileArray = {
            FileName: response.data.fileName,
            URL: response.data.httpHost + response.data.filePath,
        };
        console.log("AttachOnSuccess", fileArray);
      if (this.newList == null) {
        this.newList = new Array();
        this.newList.push(fileArray);
      } else {
        this.newList.push(fileArray);
      }
    },
    Attachexceed(files, fileList) {
      this.$message({
        message: "当前上传最大数量不超过1个。",
        type: "warning",
      });
    },
    linkDownload(url) {
      window.open(url, "_blank"); // 新窗口打开外链接
    },
  },
  watch: {
    //监听文件列表
    newList: function (newVal, oldVal) {
      console.log(1);
      this.$emit("fun", { backData: this.newList });
    },
  },
  computed: {
      btnName: function () {
          return "点击上传" + this.btnCurName;
      },
      tabName: function () {
          return this.btnCurName + "文件名称";
      }

  },
  created() {
    //父组件传递过来的附件数组

    if (this.files != null && this.files.length > 0) {
      this.newList = this.files;
    } else {
      this.newList = [];
    }
  },
};
</script>

<style scoped>
.uploadFils {
  width: 100%;
  /* margin-top: 20px; */
  /* padding: 4px; */
  /* min-height: 100px; */
  box-sizing: border-box;
}
.mt20 {
  margin-top: 20px;
}
</style>

